<template>
  <CRow>
    <CToaster :autohide="2000">
      <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>      
      <template>           
        <div v-if="showInfoToast">
          <CToast          
          :show.sync="showInfoToast"
          color="info"
          >
          {{ infoToastMessage  }}            
          </CToast>
        </div>   
      </template>                 
    </CToaster>      
    <CCol v-show="loading">
      <CCol col="12">
        <CCard v-show="loading">
          <CCardBody>
            <p class="text-center" v-show="loading">
              <CSpinner />
            </p >   
          </CCardBody>
        </CCard>
      </CCol>    
    </CCol>    
    <CCol v-show="!loading">
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CRow>
          <CCol col="2">
          <CButton class="float-left" disabled variant="ghost" color="dark">Choices</CButton>
          </CCol>
          <CCol col="6">
          <template>              
            
                  <div class='float-left col-xl-6'>
                    <multiselect 
                      v-model="selectedPillar"
                      :options="pillars" 
                      track-by="pillar_id"
                      label="pillar_name" 
                      :searchable="false" 
                      :close-on-select="true"   
                      placeholder="Select Pillar to filter..."
                      selectLabel=""
                      :multiple="false"
                      :hideSelected="false"                  
                      :allowEmpty="true"
                      :loading="loadingPillars"
                      @input="setFilterPillar"
                    />                          
                  </div>
                </template>   
          </CCol>
          <CCol col="4">
          <CButton class="ml-5 float-right"   @click="openHelp(true)" color="dark" :disabled="true"><span class="material-icons md-18">question_mark</span></CButton>
          <CButton class="ml-5 float-right" :color="transformUpdateStatusToColor()" :disabled="true" v-c-tooltip="{ content: transformUpdateStatus() }" >Status</CButton>              
          <CButton class="ml-5 float-right" variant="outline" @click="updateChoices" :disabled="checkIfUpdateIsLocked()" color="info" v-c-tooltip="{ content: 'Starts a background process to fetch all choices from AWS. Will be locked during execution.' }">          
            <div>
                <span style="vertical-align: text-bottom;" class="material-icons md-18 icon align">
                <template v-if="checkIfUpdateIsLocked()">
                  lock
                </template>
                <template v-else>
                  lock_open
                </template>
                </span>
                 Update DB 
                </div> 
              </CButton>            
          </CCol>
          </CRow>
        </CCardHeader>      
        <CCardBody>            
          <CDataTable
            hover
            striped
            sorter            
            column-filter
            items-per-page-select
            :column-filter-value="{ 'choice_lense': selectedLens, 'choice_pillar': selectedPillarId  }"
            :sorter-value="{ column: 'choice_question', asc: 'true' }"
            :items="items"
            :fields="fields"
            :items-per-page="20"      
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"          
            :loading=loading
          >                         
              <td slot="choice_question" slot-scope="{item}">
                <span class="d-inline-block text-truncate" style="max-width: 250px;">
                  {{ item.choice_question.split(" ")[0] || "N/A" }}
                </span>
              </td> 
              <!-- <td slot="choice_name" slot-scope="{item}"  v-c-tooltip="{ content: item.choice_name }"> -->
              <td slot="choice_name" slot-scope="{item}">
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <span class="d-inline-block text-truncate" style="max-width: 350px;">
                    {{ item.choice_name }}                  
                  </span>  
                  <div class="custom-tooltip" v-show="showTooltip">
                    {{ item.choice_name }}                  
                  </div>
                </div> 
              </td> 

              <!-- <td slot="choice_desc" slot-scope="{item}"  v-c-tooltip="{ content: item.choice_desc}"> -->
              <td slot="choice_desc" slot-scope="{item}" >
                <div class="tooltip-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
                  <span class="d-inline-block text-truncate" style="max-width: 400px;">
                    {{ item.choice_desc }}                  
                  </span>  
                  <div class="custom-tooltip" v-show="showTooltip">
                    {{ item.choice_desc }}                  
                  </div>
                </div>                 
              </td> 
              <td slot="choice_pillar" slot-scope="{item}" >
                  {{  getPillarName(item.choice_pillar) }}
              </td>               
              <td slot="choice_risk" slot-scope="{item}" >
                  <CButton :color="getRisk(item.choice_risk)" variant="ghost" disabled>
                  {{ item.choice_risk }}
                  </CButton>              
              </td> 
               
              <td slot="choice_view" slot-scope="{item}">
                  <CButton v-on:click="viewChoice(item)" :id="item.item_id" block color="dark">
                    <CIcon name="cil-magnifying-glass"/>
                  </CButton>
              </td>              
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <CModal
      :title="viewChoiceItem.choice_name"
      color="dark"
      :show.sync="viewModal"
    >
      <CCard>
        <CCardHeader>
          <strong>Question</strong>
        </CCardHeader>
        <CCardBody>
          {{ viewChoiceItem.choice_question }}
        </CCardBody>
      </CCard>   
      <CCard>
        <CCardHeader>
          <strong>Description</strong>
        </CCardHeader>
        <CCardBody>
          {{ viewChoiceItem.choice_desc }}
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <strong>Lense / Pillar</strong>
        </CCardHeader>
        <CCardBody>
          {{ lens.name || "N/A" }} / {{ getPillarName(viewChoiceItem.choice_pillar) }}
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <strong>Risk</strong>
        </CCardHeader>
        <CCardBody>
          {{ viewChoiceItem.choice_risk }}
        </CCardBody>
      </CCard>
     
      <div slot="footer" class="w-100">
          <CButton @click="viewModal = false"  color="light" class="ml-1 mr-1 col-sm-2 float-right" >Close</CButton>  
      </div>
    </CModal>    
  </CCol>
  </CRow>
</template>

<script>
import ChoiceService from '@/services/choice.services';
import LensService from '@/services/lense.services';
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },  
  data() {
    return {
      items: [],
      fields: [
        { key: 'choice_question', label: 'Question', _style:'width:5%' },
        { key: 'choice_name', label: 'Name', _style:'width:25%' },
        { key: 'choice_desc', label: 'Description', _style:'width:25%'  },
        // { key: 'choice_lense', label: 'Lense', _style:'width:30%', filter: false, sorter: false },
        { key: 'choice_pillar', label: 'Pillar', _style:'width:10%' },
        { key: 'choice_risk', label: 'Risk', _style:'width:5%', },
        { key: 'choice_view', label: '',  _style: 'width:1%', sorter: false, filter: false }
      ],
      lens: {},
      pillars: [],
      loading: true,
      loadingPillars: true,
      viewModal: false,
      viewChoiceItem: {},
      activePage: 1,
      selectedLens: "wellarchitected",
      selectedPillar: "",
      selectedPillarId: "",      
      showSuccessToast: false,
      showErrorToast: false,
      showInfoToast: false,
      successToastMessage: "",
      errorToastMessage: "",
      infoToastMessage: "",   
      showTooltip: false,   
      updateStatus: {},
      updateLock: {}

    };
  },

  methods: {    
    transformUpdateStatusToColor(){
      if (this.updateStatus.status === "SUCCESSFULLY"){
        return "success";
      }
      else if (this.updateStatus.status === "IN_PROGRESS"){
        return "info";
      } else {
        return "danger";
      }

    },
    transformUpdateStatus(){
      let last_update = new Date(this.updateStatus.updated).toLocaleString();

      return "Last update: " + last_update + " | Status: " + this.updateStatus.status;
    },      
    checkIfUpdateIsLocked(){
      if(this.updateLock.locked === true){
        return true;
      } else {
        return false;
      }
    },      
   updateChoices() {
      ChoiceService.triggerChoiceUpdater().then(
        response => {
          this.successToastMessage = "Database update started! This process can take up to 5 minutes.";
          this.showSuccessToast = true;
         },
        error => {   
          console.log(error);
          this.errorToastMessage = "Error starting the database update. Please try again later.";
          this.showErrorToast = true;
        }
      )
    },    
    setFilterPillar(){      
      if(this.selectedPillar == null){
        this.selectedPillarId = "";
      } else {
        this.selectedPillarId = this.selectedPillar.pillar_id || "";
      };
    },       
    getPillarName(pillarId) {
      let pillar = this.pillars.find(o => o.pillar_id === pillarId);

      if (pillar === undefined) {
        return pillarId
      }

      return pillar.pillar_name;
    
    },        
    getRisk (risk) {
      switch (risk) {
        case 'HIGH': return 'danger'
        case 'MEDIUM': return 'warning'
        case 'NONE': return 'success'
        default: 'primary'
      }
    },
    viewChoice(choice) {
      this.viewChoiceItem = choice
      this.viewModal = true
    },
  },

  async created() {
    this.selectedLens = this.$route.params.lense
  },
  async mounted() {
    ChoiceService.getChoiceUpdateStatus().then(
      response => { 
        this.updateStatus = response;
      },
      error => {   
        console.log(error)   
      }
    )  
    ChoiceService.getChoiceUpdateLock().then(
      response => { 
        this.updateLock = response;
      },
      error => {   
        console.log(error)   
      }
    )           
    await LensService.getLens(this.selectedLens).then(
      response => { 
        this.lens = response.lens;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Lens. Please try again later.";
        this.showErrorToast = true;
      }      
    )    
    await LensService.getPillars().then(
      response => { 
        this.pillars = response.pillars;
        this.loadingPillars = false;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Pillars. Please try again later.";
        this.showErrorToast = true;
      }      
    )
    await ChoiceService.getChoices().then(
      response => { 
        this.items = response;
        const indexLock = this.items.findIndex(obj => obj.choice_id === "lock");
        if (indexLock > -1) { 
          this.items.splice(indexLock, 1); 
        }
        const indexStatus = this.items.findIndex(obj => obj.choice_id === "status");

        if (indexStatus > -1) { 
          this.items.splice(indexStatus, 1); 
        }        
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Choices. Please try again later.";
        this.showErrorToast = true;
      }
    )
    this.loading = false;

  },

  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};



</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
